// Styled - Components
import styled from 'styled-components'
// MainStem - UI
import { theme } from '@mainstem/react-mainstem-omni'

const primaryColor = theme.colors.primary
const scrollbarColor = primaryColor
const scrollbarHoverColor = primaryColor

export const SCPre = styled.pre`
  margin-bottom: 25px;

  ::-webkit-scrollbar {
    background: rgba(59, 18, 141, 0.1);
    box-shadow: none;
    height: 7px;
    width: 7px;
    z-index: 9999;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent;
    box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${scrollbarColor};
    border-radius: 100px;
    box-shadow: none;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${scrollbarHoverColor};
  }
`
