// React
import { useState } from 'react'
// MainStem - UI
import { Button, Tooltip, useOnFirstLoad } from '@mainstem/react-mainstem-omni'
// MainStem - API
import { Api, MainStemAPIControllersPurchasingFeedStreamAPIResponseFeedStreamDetails } from 'api-new'
const mainStemApi = new Api()
// Icons
import { faEye } from '@fortawesome/pro-light-svg-icons'
// Local - Styles
import {
  SCFeedCard,
  SCFeedCardButton,
  SCFeedCardDescription,
  SCFeedCardImage,
  SCFeedCardImageWrapper,
  SCFeedCardTitle,
  SCFeedCardWrapper,
  SCFeedPinImage,
  SCFeedPinWrapper,
  SCFeedTitle,
  SCFeedWrapper
} from './styles'
// Local - Assets - Image
import imgThumbtack from './components/img/thumbtack.png'

const Feed: React.FC = () => {
  const [feeds, setFeeds] = useState<MainStemAPIControllersPurchasingFeedStreamAPIResponseFeedStreamDetails[]>([])

  const loadFeeds = () => {
    mainStemApi.api.feedStream().then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        if (apiResponse.data.feeds) {
          setFeeds(apiResponse.data.feeds)
        }
      }
    })
  }

  useOnFirstLoad(() => {
    loadFeeds()
  })

  return (
    <>
      <SCFeedWrapper>
        <SCFeedTitle>MainStem Feed</SCFeedTitle>
        <SCFeedCardWrapper>
          {feeds.map((feed) => {
            return (
              <SCFeedCard key={feed.id}>
                {feed.isPinned && (
                  <>
                    <SCFeedPinWrapper>
                      <Tooltip content='A pinned feed will stay at the top.' placement='top'>
                        <SCFeedPinImage src={imgThumbtack} />
                      </Tooltip>
                    </SCFeedPinWrapper>
                  </>
                )}
                {feed.imageURL && (
                  <>
                    <SCFeedCardImageWrapper>
                      <SCFeedCardImage src={feed.imageURL} />
                    </SCFeedCardImageWrapper>
                  </>
                )}
                <SCFeedCardTitle>{feed.title}</SCFeedCardTitle>
                <SCFeedCardDescription
                  dangerouslySetInnerHTML={{ __html: feed.description || '' }}
                ></SCFeedCardDescription>
                {feed.url && (
                  <>
                    <br />
                    <SCFeedCardButton href={feed.url} rel='noreferrer' target='_blank'>
                      <Button block color='fancy' icon={faEye}>
                        Read More
                      </Button>
                    </SCFeedCardButton>
                  </>
                )}
              </SCFeedCard>
            )
          })}
        </SCFeedCardWrapper>
      </SCFeedWrapper>
    </>
  )
}

export { Feed }
