// React
import { Suspense, lazy } from 'react'
// Routing
import { Redirect, Route, Switch } from 'react-router-dom'
// MainStem - UI
import { Loader } from '@mainstem/react-mainstem-omni'
// Page : Error
import PageErr from 'pages/err'
// Lazy Loaded Pages
const lazyLoadedPages = {
  account: lazy(() => import('pages/account')),
  ai: {
    chat: lazy(() => import('pages/ai/chat'))
  },
  catalog: {
    create: lazy(() => import('pages/catalog/create')),
    dashboard: lazy(() => import('pages/catalog/dashboard')),
    details: lazy(() => import('pages/catalog/details')),
    update: lazy(() => import('pages/catalog/update'))
  },
  dashboard: {
    approval: lazy(() => import('pages/dashboard/approval')),
    budgets: lazy(() => import('pages/dashboard/budgets')),
    canold: lazy(() => import('pages/dashboard/canold')),
    finance: lazy(() => import('pages/dashboard/finance')),
    integrations: lazy(() => import('pages/dashboard/integrations')),
    inventory: lazy(() => import('pages/dashboard/inventory')),
    marketplace: lazy(() => import('pages/dashboard/marketplace')),
    purchasing: lazy(() => import('pages/dashboard/purchasing')),
    supplyAudits: lazy(() => import('pages/dashboard/supply-audits')),
    tableau: lazy(() => import('pages/dashboard/tableau'))
  },
  department: {
    create: lazy(() => import('pages/department/create')),
    dashboard: lazy(() => import('pages/department/dashboard')),
    details: lazy(() => import('pages/department/details')),
    update: lazy(() => import('pages/department/update'))
  },
  feed: {
    create: lazy(() => import('pages/feed/create')),
    details: lazy(() => import('pages/feed/details')),
    update: lazy(() => import('pages/feed/update'))
  },
  feedback: lazy(() => import('pages/feedback')),
  integration: {
    acumatica: {
      details: lazy(() => import('pages/integration/acumatica/details')),
      gate: lazy(() => import('pages/integration/acumatica/gate'))
    },
    bioTrack: lazy(() => import('pages/integration/biotrack')),
    canold: lazy(() => import('pages/integration/canold')),
    d6: lazy(() => import('pages/integration/d6')),
    dashboard: lazy(() => import('pages/integration/dashboard')),
    dutchie: {
      details: lazy(() => import('pages/integration/dutchie/details')),
      gate: lazy(() => import('pages/integration/dutchie/gate'))
    },
    email: lazy(() => import('pages/integration/email')),
    excel: lazy(() => import('pages/integration/excel')),
    fundcanna: lazy(() => import('pages/integration/fundcanna')),
    google: lazy(() => import('pages/integration/google')),
    googleSheets: lazy(() => import('pages/integration/google-sheets')),
    leafLogix: lazy(() => import('pages/integration/leaflogix')),
    lendica: lazy(() => import('pages/integration/lendica')),
    microsoftDynamics: lazy(() => import('pages/integration/microsoft-dynamics')),
    payQwick: lazy(() => import('pages/integration/payqwick')),
    powerBI: lazy(() => import('pages/integration/power-bi')),
    powerQuery: lazy(() => import('pages/integration/power-query')),
    quickBooksDesktop: lazy(() => import('pages/integration/quickbooks-dekstop')),
    quickBooksOnline: {
      details: lazy(() => import('pages/integration/quickbooks-online/details')),
      gate: lazy(() => import('pages/integration/quickbooks-online/gate'))
    },
    quantumLeaf: {
      details: lazy(() => import('pages/integration/quantum-leaf/details')),
      gate: lazy(() => import('pages/integration/quantum-leaf/gate'))
    },
    sage: {
      details: lazy(() => import('pages/integration/sage-intacct/details')),
      gate: lazy(() => import('pages/integration/sage-intacct/gate'))
    },
    slack: lazy(() => import('pages/integration/slack')),
    snowflake: lazy(() => import('pages/integration/snowflake')),
    tableau: lazy(() => import('pages/integration/tableau')),
    transactionPro: lazy(() => import('pages/integration/transaction-pro')),
    weedTraQR: lazy(() => import('pages/integration/weedtraqr'))
  },
  invoices: {
    dashboard: lazy(() => import('pages/invoices/dashboard'))
  },
  invoiceInquiry: {
    details: lazy(() => import('pages/invoice-inquiry/details'))
  },
  location: {
    create: lazy(() => import('pages/location/create')),
    dashboard: lazy(() => import('pages/location/dashboard')),
    details: lazy(() => import('pages/location/details')),
    update: lazy(() => import('pages/location/update'))
  },
  makePayment: {
    invoice: lazy(() => import('pages/make-payment/invoice')),
    order: lazy(() => import('pages/make-payment/order'))
  },
  onboard: lazy(() => import('pages/onboard')),
  order: {
    details: lazy(() => import('pages/order/details')),
    dashboard: lazy(() => import('pages/order/dashboard')),
    submit: lazy(() => import('pages/order/submit'))
  },
  permissionGroup: {
    dashboard: lazy(() => import('pages/permission-group/dashboard')),
    details: lazy(() => import('pages/permission-group/details')),
    update: lazy(() => import('pages/permission-group/update'))
  },
  privateInvoice: {
    details: lazy(() => import('pages/private-invoice/details'))
  },
  product: {
    create: lazy(() => import('pages/product/create')),
    dashboard: lazy(() => import('pages/product/dashboard')),
    details: lazy(() => import('pages/product/details')),
    update: lazy(() => import('pages/product/update'))
  },
  punchOut: {
    details: lazy(() => import('pages/punch-out/details')),
    purchase: lazy(() => import('pages/punch-out/purchase')),
    settings: lazy(() => import('pages/punch-out/settings'))
  },
  receiving: {
    dashboard: lazy(() => import('pages/receiving/dashboard')),
    details: lazy(() => import('pages/receiving/details'))
  },
  report: {
    historicalOrders: lazy(() => import('pages/report/historical-orders')),
    lineItems: lazy(() => import('pages/report/line-items')),
    openOrders: lazy(() => import('pages/report/open-orders')),
    punchOuts: lazy(() => import('pages/report/punch-outs'))
  },
  rfq: {
    create: lazy(() => import('pages/rfq/create')),
    dashboard: lazy(() => import('pages/rfq/dashboard')),
    details: lazy(() => import('pages/rfq/details'))
  },
  search: lazy(() => import('pages/search')),
  shop: lazy(() => import('pages/shop')),
  shipment: {
    details: lazy(() => import('pages/shipment/details')),
    dashboard: lazy(() => import('pages/shipment/dashboard'))
  },
  supplier: {
    create: lazy(() => import('pages/supplier/create')),
    dashboard: {
      route: '/suppliers/dashboard',
      page: lazy(() => import('pages/supplier/dashboard'))
    },
    details: lazy(() => import('pages/supplier/details')),
    update: lazy(() => import('pages/supplier/update'))
  },
  supplyAudits: {
    details: lazy(() => import('pages/supply-audit/details')),
    realTime: lazy(() => import('pages/supply-audit/real-time'))
  },
  user: {
    create: lazy(() => import('pages/user/create')),
    details: lazy(() => import('pages/user/details')),
    dashboard: lazy(() => import('pages/user/dashboard')),
    update: lazy(() => import('pages/user/update'))
  }
}

export const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {/* Default - Route */}
      <Route exact path='/'>
        <Redirect to='/dashboard/marketplace' />
      </Route>
      {/* Account */}
      <Route exact path='/account'>
        <lazyLoadedPages.account />
      </Route>
      {/* AI */}
      <Route exact path='/ai/chat'>
        <lazyLoadedPages.ai.chat />
      </Route>
      {/* Catalogs */}
      <Route exact path='/catalogs/create'>
        <lazyLoadedPages.catalog.create />
      </Route>
      <Route exact path='/catalogs/dashboard'>
        <lazyLoadedPages.catalog.dashboard />
      </Route>
      <Route exact path='/catalogs/details/:id'>
        <lazyLoadedPages.catalog.details />
      </Route>
      <Route exact path='/catalogs/browser/:id?'>
        <lazyLoadedPages.shop />
      </Route>
      <Route exact path='/catalogs/update/:id'>
        <lazyLoadedPages.catalog.update />
      </Route>
      {/* Dashboards */}
      <Route exact path='/dashboard/approval'>
        <lazyLoadedPages.dashboard.approval />
      </Route>
      <Route exact path='/dashboard/budgets'>
        <lazyLoadedPages.dashboard.budgets />
      </Route>
      <Route exact path='/dashboard/canold'>
        <lazyLoadedPages.dashboard.canold />
      </Route>
      <Route exact path='/dashboard/finance'>
        <lazyLoadedPages.dashboard.finance />
      </Route>
      <Route exact path='/dashboard/integration'>
        <lazyLoadedPages.dashboard.integrations />
      </Route>
      <Route exact path='/dashboard/inventory'>
        <lazyLoadedPages.dashboard.inventory />
      </Route>
      <Route exact path='/dashboard/marketplace'>
        <lazyLoadedPages.dashboard.marketplace />
      </Route>
      <Route exact path='/dashboard/purchasing'>
        <lazyLoadedPages.dashboard.purchasing />
      </Route>
      <Route exact path='/dashboard/supply-audits'>
        <lazyLoadedPages.dashboard.supplyAudits />
      </Route>
      <Route exact path='/dashboard/tableau'>
        <lazyLoadedPages.dashboard.tableau />
      </Route>
      {/* Departments */}
      <Route exact path='/departments/create/:locationId?'>
        <lazyLoadedPages.department.create />
      </Route>
      <Route exact path='/departments/dashboard'>
        <lazyLoadedPages.department.dashboard />
      </Route>
      <Route exact path='/departments/details/:id'>
        <lazyLoadedPages.department.details />
      </Route>
      <Route exact path='/departments/update/:id'>
        <lazyLoadedPages.department.update />
      </Route>
      {/* Feed */}
      <Route exact path='/feed/create'>
        <lazyLoadedPages.feed.create />
      </Route>
      <Route exact path='/feed/details/:id'>
        <lazyLoadedPages.feed.details />
      </Route>
      <Route exact path='/feed/update/:id'>
        <lazyLoadedPages.feed.update />
      </Route>
      {/* Feedback */}
      <Route exact path='/feedback'>
        <lazyLoadedPages.feedback />
      </Route>
      {/* Integrations */}
      <Route exact path='/integrations/dashboard'>
        <lazyLoadedPages.integration.dashboard />
      </Route>
      {/* Integrations - Acumatica */}
      <Route exact path='/integrations/acumatica/details/:id'>
        <lazyLoadedPages.integration.acumatica.details />
      </Route>
      <Route exact path='/integrations/acumatica/gate'>
        <lazyLoadedPages.integration.acumatica.gate />
      </Route>
      {/* Integrations - BioTrack */}
      <Route exact path='/integrations/biotrack'>
        <lazyLoadedPages.integration.bioTrack />
      </Route>
      {/* Integrations - Canold */}
      <Route exact path='/integrations/canold'>
        <lazyLoadedPages.integration.canold />
      </Route>
      {/* Integrations - D6 */}
      <Route exact path='/integrations/d6/:debug?'>
        <lazyLoadedPages.integration.d6 />
      </Route>
      {/* Integrations - Dutchie */}
      <Route exact path='/integrations/dutchie/details/:id'>
        <lazyLoadedPages.integration.dutchie.details />
      </Route>
      <Route exact path='/integrations/dutchie/gate'>
        <lazyLoadedPages.integration.dutchie.gate />
      </Route>
      {/* Integrations - Email */}
      <Route exact path='/integrations/email'>
        <lazyLoadedPages.integration.email />
      </Route>
      {/* Integrations - Excel */}
      <Route exact path='/integrations/excel'>
        <lazyLoadedPages.integration.excel />
      </Route>
      {/* Integrations - FundCanna */}
      <Route exact path='/integrations/fundcanna'>
        <lazyLoadedPages.integration.fundcanna />
      </Route>
      {/* Integrations - Google */}
      <Route exact path='/integrations/google'>
        <lazyLoadedPages.integration.google />
      </Route>
      {/* Integrations - Google - Sheets */}
      <Route exact path='/integrations/google/sheets'>
        <lazyLoadedPages.integration.googleSheets />
      </Route>
      {/* Integrations - LeafLogix */}
      <Route exact path='/integrations/leaflogix'>
        <lazyLoadedPages.integration.leafLogix />
      </Route>
      {/* Integrations - Lendica */}
      <Route exact path='/integrations/lendica/:debug?'>
        <lazyLoadedPages.integration.lendica />
      </Route>
      {/* Integrations - Microsoft Dynamics */}
      <Route exact path='/integrations/microsoft-dynamics-365'>
        <lazyLoadedPages.integration.microsoftDynamics />
      </Route>
      {/* Integrations - PayQwick */}
      <Route exact path='/integrations/payqwick'>
        <lazyLoadedPages.integration.payQwick />
      </Route>
      {/* Integrations - PowerBI */}
      <Route exact path='/integrations/power-bi'>
        <lazyLoadedPages.integration.powerBI />
      </Route>
      {/* Integrations - Power Query */}
      <Route exact path='/integrations/power-query'>
        <lazyLoadedPages.integration.powerQuery />
      </Route>
      {/* Integrations - QuantumLeaf */}
      <Route exact path='/integrations/quantum-leaf/details/:id'>
        <lazyLoadedPages.integration.quantumLeaf.details />
      </Route>
      <Route exact path='/integrations/quantum-leaf/gate'>
        <lazyLoadedPages.integration.quantumLeaf.gate />
      </Route>
      {/* Integrations - QuickBooks - Desktop */}
      <Route exact path='/integrations/quickbooks-desktop'>
        <lazyLoadedPages.integration.quickBooksDesktop />
      </Route>
      {/* Integrations - QuickBooks - Online */}
      <Route exact path='/integrations/quickbooks-online/gate'>
        <lazyLoadedPages.integration.quickBooksOnline.gate />
      </Route>
      <Route exact path='/integrations/quickbooks-online/details/:id'>
        <lazyLoadedPages.integration.quickBooksOnline.details />
      </Route>
      {/* Integrations - Sage Intacct */}
      <Route exact path='/integrations/sage-intacct/gate'>
        <lazyLoadedPages.integration.sage.gate />
      </Route>
      <Route exact path='/integrations/sage-intacct/details/:id'>
        <lazyLoadedPages.integration.sage.details />
      </Route>
      {/* Integrations - Slack */}
      <Route exact path='/integrations/slack'>
        <lazyLoadedPages.integration.slack />
      </Route>
      {/* Integrations - Snowflake */}
      <Route exact path='/integrations/snowflake'>
        <lazyLoadedPages.integration.snowflake />
      </Route>
      {/* Integrations - Tableau */}
      <Route exact path='/integrations/tableau'>
        <lazyLoadedPages.integration.tableau />
      </Route>
      {/* Integrations - TransactionPro */}
      <Route exact path='/integrations/transaction-pro'>
        <lazyLoadedPages.integration.transactionPro />
      </Route>
      {/* Integrations - WeedTraQR */}
      <Route exact path='/integrations/weedtraqr'>
        <lazyLoadedPages.integration.weedTraQR />
      </Route>
      {/* Invoices */}
      <Route exact path='/invoices/dashboard'>
        <lazyLoadedPages.invoices.dashboard />
      </Route>
      {/* Invoice Inquiry */}
      <Route exact path='/invoice-inquiry/details/:id'>
        <lazyLoadedPages.invoiceInquiry.details />
      </Route>
      {/* Location */}
      <Route exact path='/locations/create'>
        <lazyLoadedPages.location.create />
      </Route>
      <Route exact path='/locations/dashboard/:action?'>
        <lazyLoadedPages.location.dashboard />
      </Route>
      <Route exact path='/locations/details/:id'>
        <lazyLoadedPages.location.details />
      </Route>
      <Route exact path='/locations/update/:id'>
        <lazyLoadedPages.location.update />
      </Route>
      {/* Make Payment */}
      <Route exact path='/make-payment/order/:type'>
        <lazyLoadedPages.makePayment.order />
      </Route>
      <Route exact path='/make-payment/invoice/:type'>
        <lazyLoadedPages.makePayment.invoice />
      </Route>
      {/* Onboard */}
      <Route exact path='/onboard/:walkThrough?'>
        <lazyLoadedPages.onboard />
      </Route>
      {/* Order */}
      <Route exact path='/orders/dashboard'>
        <lazyLoadedPages.order.dashboard />
      </Route>
      <Route path={'/orders/details/:uuid/:paymentStatus'}>
        <lazyLoadedPages.order.details />
      </Route>
      <Route path={'/orders/details/:uuid'}>
        <lazyLoadedPages.order.details />
      </Route>
      <Route exact path='/orders/submit/:uuid'>
        <lazyLoadedPages.order.submit />
      </Route>
      {/* Permission Group */}
      <Route exact path='/permission-group/dashboard'>
        <lazyLoadedPages.permissionGroup.dashboard />
      </Route>
      <Route exact path='/permission-group/details/:id'>
        <lazyLoadedPages.permissionGroup.details />
      </Route>
      <Route exact path='/permission-group/update/:id'>
        <lazyLoadedPages.permissionGroup.update />
      </Route>
      {/* Private Invoice */}
      <Route exact path='/private-invoice/details/:uuid/:invoiceUUID'>
        <lazyLoadedPages.privateInvoice.details />
      </Route>
      {/* Product */}
      <Route exact path='/products/create/:supplierId?'>
        <lazyLoadedPages.product.create />
      </Route>
      <Route exact path='/products/dashboard'>
        <lazyLoadedPages.product.dashboard />
      </Route>
      <Route exact path='/products/details/:id'>
        <lazyLoadedPages.product.details />
      </Route>
      <Route exact path='/products/update/:id'>
        <lazyLoadedPages.product.update />
      </Route>
      {/* Punch Out */}
      <Route path={'/punch-outs/details/:id'}>
        <lazyLoadedPages.punchOut.details />
      </Route>
      <Route path={'/punch-outs/details/:id/:action'}>
        <lazyLoadedPages.punchOut.details />
      </Route>
      <Route exact path='/punch-outs/purchase'>
        <lazyLoadedPages.punchOut.purchase />
      </Route>
      <Route exact path='/punch-outs/settings'>
        <lazyLoadedPages.punchOut.settings />
      </Route>
      {/* Receiving */}
      <Route exact path='/receiving/dashboard'>
        <lazyLoadedPages.receiving.dashboard />
      </Route>
      <Route exact path='/receiving/details/:id?'>
        <lazyLoadedPages.receiving.details />
      </Route>
      {/* Report */}
      <Route exact path='/reports/historical-orders'>
        <lazyLoadedPages.report.historicalOrders />
      </Route>
      <Route exact path='/reports/line-items'>
        <lazyLoadedPages.report.lineItems />
      </Route>
      <Route exact path='/reports/open-orders'>
        <lazyLoadedPages.report.openOrders />
      </Route>
      <Route exact path='/reports/punch-outs'>
        <lazyLoadedPages.report.punchOuts />
      </Route>
      {/* RFQ */}
      <Route exact path='/rfq/create'>
        <lazyLoadedPages.rfq.create />
      </Route>
      <Route exact path='/rfq/dashboard'>
        <lazyLoadedPages.rfq.dashboard />
      </Route>
      <Route exact path='/rfq/details/:id'>
        <lazyLoadedPages.rfq.details />
      </Route>
      {/* Search */}
      <Route exact path='/search/:query?'>
        <lazyLoadedPages.search />
      </Route>
      {/* Shipment */}
      <Route exact path='/shipments/dashboard'>
        <lazyLoadedPages.shipment.dashboard />
      </Route>
      <Route exact path='/shipments/details/:id'>
        <lazyLoadedPages.shipment.details />
      </Route>
      {/* Shop */}
      <Route exact path='/shop/:catalogId?'>
        <lazyLoadedPages.shop />
      </Route>
      {/* Supplier */}
      <Route exact path='/suppliers/create'>
        <lazyLoadedPages.supplier.create />
      </Route>
      <Route exact path={lazyLoadedPages.supplier.dashboard.route}>
        <lazyLoadedPages.supplier.dashboard.page />
      </Route>
      <Route exact path='/suppliers/details/:id'>
        <lazyLoadedPages.supplier.details />
      </Route>
      <Route exact path='/suppliers/update/:id'>
        <lazyLoadedPages.supplier.update />
      </Route>
      {/* Supply Audit */}
      <Route exact path='/supply-audit/details/:id'>
        <lazyLoadedPages.supplyAudits.details />
      </Route>
      <Route exact path='/supply-audit/real-time/:id'>
        <lazyLoadedPages.supplyAudits.realTime />
      </Route>
      <Route exact path='/order/recommendation-engine/:id'>
        <lazyLoadedPages.supplyAudits.realTime />
      </Route>
      {/* User */}
      <Route exact path='/users/create'>
        <lazyLoadedPages.user.create />
      </Route>
      <Route exact path='/users/dashboard'>
        <lazyLoadedPages.user.dashboard />
      </Route>
      <Route exact path='/users/details/:uuid'>
        <lazyLoadedPages.user.details />
      </Route>
      <Route exact path='/users/update/:uuid'>
        <lazyLoadedPages.user.update />
      </Route>
      {/* 404 / Route Not Found */}
      <Route>
        <PageErr />
      </Route>
    </Switch>
  </Suspense>
)
