// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { Badge, SelectSingle, Spinner, useOnFirstLoad } from '@mainstem/react-mainstem-omni'
// MainStem - API
import { Api } from 'api-new'
// Global - Helpers
import { LeafLogixTypeAsString, leafLogixLoadProducts, loadLeafLogixRooms, loadLeafLogixVendors } from 'helpers'
// Global - Types
import { LeafLogixTypes } from 'types'
// Local - Styled
import { SCLeafLogixLookup } from './styles'
// Local - Types
import { ILeafLogixLookup } from './types'

const LeafLogixLookup = ({
  defaultValue = '',
  disabled = false,
  onSelected,
  setInitialLeafLogixID,
  options,
  type,
  customerIntegrationID,
  recordID
}: ILeafLogixLookup) => {
  const mainStemAPI = new Api()

  const [loadingFind, setLoadingFind] = useState<boolean>(false)
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false)
  const [localDefaultOption, setLocalDefaultOption] = useState<any>()
  const [localOptions, setLocalOptions] = useState<any[]>(options ?? [])

  const loadList = () => {
    if (!customerIntegrationID) return Promise.resolve([])
    switch (type) {
      case LeafLogixTypes.Products:
        return leafLogixLoadProducts(customerIntegrationID, setLoadingOptions, setLocalOptions)
      case LeafLogixTypes.Rooms:
        return loadLeafLogixRooms(customerIntegrationID, setLoadingOptions, setLocalOptions)
      case LeafLogixTypes.Vendors:
        return loadLeafLogixVendors(customerIntegrationID, setLoadingOptions, setLocalOptions)
      default:
        return Promise.resolve([])
    }
  }

  const handleFindDefaultValue = (recordID: number, type: LeafLogixTypes, customerIntegrationID: number) => {
    if (!customerIntegrationID) return Promise.resolve(undefined)
    return mainStemAPI.api
      .integrationLeafLogixGetByType({
        recordID: recordID,
        type: LeafLogixTypeAsString(type),
        customerIntegrationSettingID: customerIntegrationID
      })
      .then((response) => {
        const responseData = response.data
        return responseData ?? response
      })
  }

  // Fires when an item is selected in the list
  const handleSelected = (selectedOption: any) => {
    setLocalDefaultOption(selectedOption)
    if (onSelected) {
      if (selectedOption) {
        onSelected(selectedOption.value)
      } else {
        onSelected(undefined)
      }
    }
  }

  // Fires if the default value changes
  useOnFirstLoad(() => {
    if (customerIntegrationID) {
      loadList().then((listResponse) => {
        if (recordID && !defaultValue) {
          setLoadingFind(true)
          // If no default value exists & we have a recordID, try to find the default value
          handleFindDefaultValue(recordID, type, customerIntegrationID).then((response) => {
            const leafLogixLinkID = response?.selectionResult?.leafLogixLinkID
            if (onSelected) {
              onSelected(leafLogixLinkID)
            }
            if (setInitialLeafLogixID) {
              setInitialLeafLogixID(leafLogixLinkID)
            }
            const selectedOption = listResponse.find(
              // Using weak comparison -- value/defaultValue may be a string or number - imc.
              (option: any) => option.value == leafLogixLinkID
            )
            setLocalDefaultOption(selectedOption)
            setLoadingFind(false)
          })
        } else if (defaultValue && localOptions) {
          const selectedOption = localOptions.find(
            // Using weak comparison -- value/defaultValue may be a string or number - imc.
            (option: any) => option.value == defaultValue
          )
          setLocalDefaultOption(selectedOption)
        }
      })
    }
  })

  useEffect(() => {
    if (defaultValue && localOptions) {
      const selectedOption = localOptions.find(
        // Using weak comparison -- value/defaultValue may be a string or number - imc.
        (option: any) => option.value == defaultValue
      )
      setLocalDefaultOption(selectedOption)
    }
  }, [defaultValue, localOptions])

  return (
    <>
      <SCLeafLogixLookup>
        {loadingOptions || loadingFind ? (
          <Spinner />
        ) : (
          <>
            {localOptions && localOptions.length > 0 ? (
              <>
                <SelectSingle
                  disabled={disabled}
                  onChange={handleSelected}
                  options={localOptions}
                  placeholder={`Search ${LeafLogixTypeAsString(type)} in Dutchie`}
                  value={localDefaultOption}
                />
              </>
            ) : (
              <Badge color='danger'>Failed to connect to LeafLogix!</Badge>
            )}
          </>
        )}
      </SCLeafLogixLookup>
    </>
  )
}

export { LeafLogixLookup }
