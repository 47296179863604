import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
// Global App Config
import { AppContext } from 'context'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, EventBus, Loader } from '@mainstem/react-mainstem-omni'
import { apiPunchoutOrderGenerate } from 'api/punchouts/punchout-generate-order'

/* =============================================================================
                              PagePunchOutOrderGenerating
============================================================================= */

const PagePunchOutOrderGenerating = () => {
  const history = useHistory()
  const { loggedInUser } = useContext(AppContext)

  const { buyersCookie } = useParams<{ buyersCookie: any }>()
  const [firstLoad, setFirstLoad] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /* ======================
        generateOrder()
  ====================== */

  const generateOrder = useCallback(() => {
    if (isLoading || !loggedInUser) {
      return
    }

    setIsLoading(true)

    apiPunchoutOrderGenerate({ buyersCookie }).then((response: any) => {
      if (response.wasSuccessful) {
        EventBus.dispatch(
          window.parent.document,
          'punchout-redirect-to-order',
          {
            orderUUID: response.orderUUID
          }
        )

        history.push(`/orders/details/${response.orderUUID}`)
      } else {
        // dispatch args: document, event, data
        EventBus.dispatch(
          window.parent.document,
          'punchout-generate-failed',
          null
        )
        history.push(`/punch-outs/purchase?orderGenerationFailed=true`)
        window.location.reload()
      }
      setIsLoading(false)
    })
  }, [history, isLoading, loggedInUser])

  /* ======================
        useEffect()
  ====================== */

  useEffect(() => {
    if (firstLoad) {
      return
    }

    setFirstLoad(true)
    generateOrder()
  }, [firstLoad, buyersCookie, generateOrder])

  /* ======================
          return
  ====================== */

  return (
    <Fragment>
      <Alert
        color='success'
        style={{
          margin: '25px auto',
          maxWidth: 'calc(100vw - 20px)',
          width: 800
        }}
        title='Order Captured!'
      >
        <p>Order is being generated - You'll be redirected shortly.</p>
      </Alert>

      <Loader />
    </Fragment>
  )
}

export default PagePunchOutOrderGenerating
