// MainStem - UI
import { faFileCertificate, faPalette } from '@fortawesome/pro-light-svg-icons'
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from '@mainstem/react-mainstem-omni'
// Global - Config
import { quickRFQLinkCustomPackaging, quickRFQLinkOther } from 'config'
// Local - Types
import { IModalQuickRFQ } from './types'

const ModalQuickRFQ: React.FC<IModalQuickRFQ> = ({ onClose }: IModalQuickRFQ) => {
  return (
    <>
      <Modal onClose={onClose}>
        <ModalHeader>Quick RFQ™</ModalHeader>
        <ModalBody>
          <p>Easily start a new RFQ and have the MainStem team review your request and provide a quote.</p>
          <p>Click a button below to get started!</p>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup style={{ width: '100%' }}>
            <Button block color='primary' href={quickRFQLinkCustomPackaging} icon={faPalette}>
              Custom Packaging
            </Button>
            <Button block color='primary' href={quickRFQLinkOther} icon={faFileCertificate}>
              All Others
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}
export { ModalQuickRFQ }
