import styled from 'styled-components'

export const SCLendicaButton = styled.button`
  align-items: center;
  background: #2e3192;
  border: 0 none;
  border-radius: 50em;
  display: flex;
  justify-content: center;
  margin: 0;
  outline: 0;
  padding: 8px 16px;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
