// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import { Button } from '@mainstem/react-mainstem-omni'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons'

const ButtonGoBack = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <>
      <Button color='dark' onClick={handleGoBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
        &nbsp;Go Back
      </Button>
    </>
  )
}

export { ButtonGoBack }
