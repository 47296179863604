// React

// Local - Styled Components
import { SCNavTopAlertBar } from './styles'

const NavTopAlertBar = () => {
  return (
    <SCNavTopAlertBar to='/rfq/dashboard'>
      You can now create <strong>Requests For Quotes</strong> in your Purchase
      Pro™ account!
    </SCNavTopAlertBar>
  )
}

export default NavTopAlertBar
