// Styled Components
import styled from 'styled-components'
// MainStem - Components
import { theme } from '@mainstem/react-mainstem-omni'

export const SCLeafLogixLookup = styled.div`
  .select-leaflogix-lookup__control {
    cursor: pointer;
    min-width: 200px;
  }

  .select-leaflogix-lookup__placeholder {
    font-family: '${theme.fonts.primary}', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }

  .select-leaflogix-lookup__menu {
    background-color: #fff;
    position: relative !important;
  }

  .select-leaflogix-lookup__menu-list {
    background-color: #fff;
    z-index: 99999;

    ::-webkit-scrollbar-thumb {
      box-shadow: rgba(125, 125, 125, 0.65) 4px 4px 4px 4px inset;
      cursor: pointer;
    }
    ::-webkit-scrollbar-thumb {
      box-shadow: #fff 4px 4px 4px 4px inset;
      cursor: pointer;
    }
    ::-webkit-scrollbar-track {
      box-shadow: rgba(0, 0, 0, 0.65) 0 0 6px inset;
    }
  }

  .select-leaflogix-lookup__option {
    background-color: #fff;
    cursor: pointer !important;

    :is-focused {
      background-color: rgb(173, 216, 230);
    }
  }

  .select-leaflogix-lookup__group {
    padding: 0;
  }
`
