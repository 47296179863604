import axios from 'axios'

const enterpriseToken = window.localStorage.getItem('mainstem_user_token')

// LogRocket Session URL
const sessionURL = window.localStorage.getItem('logrocket_session_url')

export const baseURL = import.meta.env.VITE_REACT_APP_BASE_API_URL
export const payQwickClientURL = import.meta.env.VITE_REACT_APP_PAYQWICK_CLIENT_URL
export const googleSheetsTemplateURL = import.meta.env.VITE_REACT_APP_GOOGLE_SHEETS_TEMPLATE_URL
export const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID
export const ecommerceURL = import.meta.env.VITE_REACT_APP_ECOMMERCE_URL
export const publicAPIURL = import.meta.env.VITE_REACT_APP_PUBLIC_API_URL ?? ''
export const publicAPIURLDocumentation = `${import.meta.env.VITE_REACT_APP_PUBLIC_API_URL}/swagger`
export const d6APIURL = import.meta.env.VITE_REACT_APP_D6_API_URL
export const d6APIKey = import.meta.env.VITE_REACT_APP_D6_API_KEY
export const lendicaAPIURL = import.meta.env.VITE_REACT_APP_LENDICA_API_URL
export const lendicaAPIKey = import.meta.env.VITE_REACT_APP_LENDICA_API_KEY
export const leafLogixAPIURL = import.meta.env.VITE_REACT_APP_LEAF_LOGIX_API_URL
export const leafLogixAPIKey = import.meta.env.VITE_REACT_APP_LEAF_LOGIX_API_KEY

export const defaultTitle = 'MainStem - Purchase Pro™'

export const quickRFQLinkCustomPackaging = 'https://forms.microsoft.com/r/bmtCq0EXyC'
export const quickRFQLinkOther = 'https://forms.microsoft.com/r/iJKjYGWCCL'

export const lendicaConfig = {
  baseURL: import.meta.env.VITE_REACT_APP_LENDICA_BASE_URL,
  environment: import.meta.env.VITE_REACT_APP_LENDICA_ENVIRONMENT,
  partner_name: import.meta.env.VITE_REACT_APP_LENDICA_PARTNER_NAME
}

// api URL - App API
export const apiURL = '/api/purchasing/'

function logUnsuccessfulResponses(response: any) {
  return response
}

// Set Axios defaults
const HTTP = axios.create({
  baseURL: baseURL + apiURL,
  headers: {
    Authorization: `Bearer ${enterpriseToken || ''}`,
    'X-AffiliateProgramUUID': affiliateProgramUUID,
    'X-SessionURL': sessionURL,
    'X-Platform': 'Purchase Pro'
  }
})
HTTP.interceptors.response.use(logUnsuccessfulResponses)

// Set Axios defaults - Base API
const HTTPBase = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${enterpriseToken || ''}`,
    'X-SessionURL': sessionURL,
    'X-Platform': 'Purchase Pro'
  }
})
HTTPBase.interceptors.response.use(logUnsuccessfulResponses)

// Set Axios defaults
const HTTPLeafLogix = axios.create({
  baseURL: leafLogixAPIURL,
  headers: {
    Authorization: `Basic ${leafLogixAPIKey}`
  }
})

export const userPermissionError =
  'Your user lacks permissions to access these controls, please contact your manager if you would like to gain access.'

export const colors = [
  '#01b5e2',
  '#440099',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
]

export const themeColors = {
  primary: '#01b5e2',
  secondary: '#440099',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
}

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000',
  red: '#056i9567'
}

export { HTTP, HTTPBase, HTTPLeafLogix }
