import { HTTP } from 'config'

export async function apiPunchoutOrderGenerate(apiRequest: any) {
  let apiResponse = {} as any

  await HTTP.post(`punchouts/order-complete`, apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data
      }
    })
    .catch(() => {
      // means no api response returned
    })

  return apiResponse
}
