// 3rd Party CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '@mainstem/mainstem-react-app/dist/index.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'video-react/dist/video-react.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// Local CSS
import './assets/scss/utilities.scss'
import './assets/scss/layout.scss'
// React
import { Suspense, lazy, useCallback, useEffect, useState } from 'react'
// Routing
import { BrowserRouter } from 'react-router-dom'
// Microsoft - Application Insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
// MainStem - UI
import { ToastContainer } from '@mainstem/react-mainstem-omni'
// DEPRECATE : MainStem
import { AlertVersionUpgrade, MainStemBase } from '@mainstem/mainstem-react-app'
// DEPRECATE : API
import { APIAuthLogout } from 'api/auth/logout'
import { apiAuthCurrentUser } from 'api/auth/current-user'
import { apiStatusCheck } from 'api/statusCheck'
// MainStem - API
import { MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse } from 'api-new'
// Global - Context
import { AppContext, AppContextDetails } from 'context'
// Global - Config
import { HTTP, affiliateProgramUUID, baseURL, ecommerceURL } from 'config'
// Global - Pages
import PagePunchOutOrderFailed from 'pages/punch-out/order-failed'
import PagePunchOutOrderGenerating from 'pages/punch-out/order-generating'
// Local  - Components
import NavTopButtons from './components/nav-top/buttons'
import NavTopIcons from './components/nav-top/icons'
import NavTopCTA from './components/nav-top/cta'
import NavTopSearch from './components/nav-top/search'
import { links } from './components/nav-left/links'
import { navTopUserLinks } from './components/nav-top/links'
import { LayoutImpersonate } from './components/impersonate'
import NavTopAlertBar from './components/nav-top/alert-bar'
import { ErrorWrapper } from './components/error-wrapper'
import { DefaultTitle } from './components/default-title'

const ThemeBold = lazy(() => import('./components/theme-bold'))
const ThemeWhite = lazy(() => import('./components/theme-white'))

const reactPlugin = new ReactPlugin()

declare global {
  interface Window {
    analytics: any
  }
}

const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_REACT_APP_APPLICATION_INSIGHTS,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
})

appInsights.loadAppInsights()

const Layout = () => {
  // Set the baseURL in local storage for use in @mainstem/react-mainstem
  window.localStorage.setItem('baseURL', baseURL)

  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [theme, setTheme] = useState<string | undefined>(window.localStorage.getItem('theme'))
  const [checkedForInitialUser, setCheckedForInitialUser] = useState<boolean>(false)
  const [loggedInUser, setLoggedInUser] = useState<
    MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse | undefined
  >(undefined)
  const [userToken, setUserToken] = useState<string>()
  const [permissions, setPermissions] = useState<any>(false)
  const [leftLinks, setLeftLinks] = useState<any>([])

  const contextValue = {
    loggedInUser,
    setLoggedInUser,
    userToken,
    setUserToken,
    permissions,
    setPermissions
  } as AppContextDetails

  const handleStatusCheck = () => {
    apiStatusCheck().then((_apiResponse) => {
      //
    })
  }

  const handleGetCurrentUser = useCallback(() => {
    apiAuthCurrentUser().then(async (apiResponseCurrentUser) => {
      if (apiResponseCurrentUser.wasSuccessful) {
        await links(apiResponseCurrentUser).then((response) => {
          setLeftLinks(response)
        })
        if (apiResponseCurrentUser.allowPurchasingApp) {
          setLoggedInUser(apiResponseCurrentUser)
          setUserToken(window.localStorage.getItem('mainstem_user_token'))
        } else {
          const token = window.localStorage.getItem('mainstem_user_token')
          window.localStorage.removeItem('mainstem_user_token')
          window.location.href = ecommerceURL + '/account-sync/' + token
        }
      }
      setCheckedForInitialUser(true)
    })
  }, [])

  const handleLogin = async (token: any) => {
    window.localStorage.setItem('mainstem_user_token', token)
    HTTP.defaults.headers.Authorization = `Bearer ${token}`
    await handleGetCurrentUser()
    window.location.href = '/'
  }

  const handleLogout = () => {
    APIAuthLogout().then(() => {
      setLoggedInUser(undefined)
    })
  }

  const publicRoutes = [
    {
      path: '/punch-outs/order-failed/:buyersCookie?',
      component: <PagePunchOutOrderFailed />
    },
    {
      path: '/punch-outs/order-generating/:buyersCookie',
      component: <PagePunchOutOrderGenerating />
    }
  ]

  const appSettings = {
    affiliateUUID: affiliateProgramUUID,
    appName: 'Purchase Pro™',
    baseURL,
    checkedForInitialUser,
    forceLogin: true,
    impersonationComponent: <LayoutImpersonate />,
    publicizedRoutes: publicRoutes,
    links: leftLinks,
    loggedInUser,
    logo:
      theme === 'ms-theme-1' ? 'https://templates.mainstem.io/apps/lib/components/Layout/NavTop/mainstem-logo.svg' : '',
    navTopAlertBar: <NavTopAlertBar />,
    navTopButtons: <NavTopButtons />,
    navTopCTA: <NavTopCTA />,
    navTopIcons: <NavTopIcons />,
    navTopSearch: <NavTopSearch />,
    navTopUserLinks: navTopUserLinks(loggedInUser),
    onLogin: handleLogin,
    onLogout: handleLogout,
    routes: <ErrorWrapper />,
    showNavLeft: true,
    theme: !theme ? 'ms-theme-2' : theme,
    userImage: loggedInUser?.organization?.logoURL,
    userToken
  }

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)

      const themeFromLocal = window.localStorage.getItem('theme')

      if (themeFromLocal) {
        setTheme(themeFromLocal)
      }

      handleGetCurrentUser()
      handleStatusCheck()
    }
  }, [firstLoad, handleGetCurrentUser])

  return (
    <AppContext.Provider value={contextValue}>
      <AppInsightsContext.Provider value={reactPlugin}>
        {theme === 'ms-theme-1' ? (
          <>
            <Suspense fallback={<></>}>
              <ThemeWhite />
            </Suspense>
          </>
        ) : (
          <>
            <Suspense fallback={<></>}>
              <ThemeBold />
            </Suspense>
          </>
        )}
        <BrowserRouter>
          <DefaultTitle />
          <MainStemBase settings={appSettings} />
          <AlertVersionUpgrade />
          <ToastContainer />
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </AppContext.Provider>
  )
}

export default withAITracking(reactPlugin, Layout, '', 'hoc-root')
