// React
import { useCallback, useContext, useEffect } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import { Loader, toast } from '@mainstem/react-mainstem-omni'
// Global - Config
import { HTTP } from 'config'
// Global - Context
import { AppContext, AppContextDetails } from 'context'
// API's
import { apiAuthCurrentUser } from 'api/auth/current-user'
// Local - TypeScript Types
import { ILayoutImpersonateParams } from './types'

export const LayoutImpersonate = () => {
  // Routing
  const history = useHistory()
  // Route Params
  const { redirect, token } = useParams<ILayoutImpersonateParams>()
  // Global - Context
  const { setLoggedInUser, setUserToken } = useContext<AppContextDetails>(AppContext)

  const handleGetCurrentUser = useCallback(() => {
    // Attempt to get details about the currently logged in user
    apiAuthCurrentUser().then((apiResponseCurrentUser) => {
      if (apiResponseCurrentUser.wasSuccessful) {
        setLoggedInUser(apiResponseCurrentUser)
        setUserToken(window.localStorage.getItem('mainstem_user_token'))
        toast.success('Account synced successfully.')
        // Redirect back to main page.
        if (redirect) {
          if (redirect === 'onboard') {
            history.push('/onboard/start-tour')
            window.location.reload()
          } else {
            history.push('/' + redirect)
            window.location.reload()
          }
        } else {
          history.push('/')
          window.location.reload()
        }
      } else {
        history.push('/auth/login')
        toast.error('Please try to login.')
      }
    })
  }, [history, setLoggedInUser, setUserToken, redirect])

  // If the token from the URL has detected a change
  useEffect(() => {
    if (token) {
      window.localStorage.setItem('mainstem_user_token', token)
      HTTP.defaults.headers.Authorization = `Bearer ${token}`
      handleGetCurrentUser()
    }
  }, [token, handleGetCurrentUser])

  return <Loader />
}
