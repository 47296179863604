// Routing
import { useHistory } from 'react-router-dom'
// Algolia
import algoliasearch from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
// Local - Components
import { Autocomplete, ProductItem } from './components'

const searchClient = algoliasearch('MN5FZREJAS', '4635eeb818e1e4b6b8ba9bf7af6e0d9a')

const SearchLive: React.FC = () => {
  const history = useHistory()

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source }) {
      return {
        ...source,
        onSelect: ({ state }) => {
          history.push(`/shop/?query=${state.query}`)
        }
      }
    }
  })

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: 'Marketplace 1.5_query_suggestions',
    transformSource({ source }) {
      console.log('querySuggestion transform', source)
      return {
        ...source,
        onSelect: ({ state }) => {
          history.push(`/shop/?query=${state.query}`)
        }
      }
    }
  })

  return (
    <>
      <Autocomplete
        debug={false}
        getSources={({ query }) => [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'Marketplace 1.5',
                    query
                  }
                ]
              })
            },
            onSelect: ({ state }) => {
              history.push(`/shop/?query=${state.query}`)
            },
            templates: {
              item({ item, components }) {
                return <ProductItem components={components} hit={item} />
              }
            }
          }
        ]}
        insights={true}
        onSubmit={({ state }) => {
          history.push(`/shop/?query=${state.query}`)
        }}
        openOnFocus={true}
        placeholder='Search through products, categories and suppliers... '
        querySuggestionsPlugin={querySuggestionsPlugin}
        recentSearchesPlugin={recentSearchesPlugin}
      />
    </>
  )
}

export { SearchLive }
