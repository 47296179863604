// React
import { useEffect, useState } from 'react'
// Routing
import { Link, useLocation } from 'react-router-dom'
// DEPRECATE : ClassNames
import classNames from 'classnames'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faFileCertificate, faGraduationCap, faLifeRing, faRobot } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { ButtonGoBack, ModalQuickRFQ } from 'components'
import { SearchLive } from './components'
import { Button } from '@mainstem/react-mainstem-omni'

const NavTopButtons: React.FC = () => {
  // Routing
  const location = useLocation()
  // Modal
  const [showModalQuickRFQ, setShowModalQuickRFQ] = useState(false)
  // View State
  const [currentPath, setCurrentPath] = useState<string>('')

  /***
   * Fires everytime the URL route/path changes
   */
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  return (
    <>
      <ButtonGoBack />
      <Button
        color='dark'
        icon={faFileCertificate}
        onClick={() => {
          setShowModalQuickRFQ(true)
        }}
      >
        Quick RFQ™
      </Button>
      <div style={{ minWidth: '550px', width: '100%' }}>
        <SearchLive />
      </div>
      {/* <Link className={classNames('ms-nav-top-middle-menu-link', currentPath === '/shop' ? 'active' : '')} to='/shop'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faCartPlus} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Marketplace™</div>
      </Link>
 
      <Link className='ms-nav-top-middle-menu-link' to='/onboard'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faGraduationCap} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Product Tour</div>
      </Link>
      <a
        className='ms-nav-top-middle-menu-link'
        href='https://support.mainstem.io'
        rel='noopener noreferrer'
        target='_blank'
      >
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faLifeRing} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Support</div>
      </a>
      <Link className='ms-nav-top-middle-menu-link' to='/ai/chat'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faRobot} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>AI GPT Chat</div>
      </Link> */}
      {/* {showWhatsNew && (
        <div className='ms-nav-top-middle-menu-link' id='ms-nav-top-button-release'>
          <div className='ms-nav-top-middle-menu-icon'>
            <FontAwesomeIcon icon={faGem} />
          </div>
          <div className='ms-nav-top-middle-menu-text'>What's New?</div>
        </div>
      )}
      {showProvideFeedback && (
        <a className='ms-nav-top-middle-menu-link' href='/feedback' rel='noopener noreferrer' target='_blank'>
          <div className='ms-nav-top-middle-menu-icon'>
            <FontAwesomeIcon icon={faCommentAltDots} />
          </div>
          <div className='ms-nav-top-middle-menu-text'>Provide Feedback</div>
        </a>
      )} */}
      {/* <div className='ms-nav-top-middle-menu-link active'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faStore} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Shop</div>
      </div>
      <div className='ms-nav-top-middle-menu-link'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faFileAlt} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Policies</div>
      </div>
      <div className='ms-nav-top-middle-menu-link'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faGavel} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Compliance</div>
      </div>
    */}
      {showModalQuickRFQ && (
        <ModalQuickRFQ
          onClose={() => {
            setShowModalQuickRFQ(false)
          }}
        />
      )}
    </>
  )
}

export default NavTopButtons
