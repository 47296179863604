// LogRocket
import LogRocket from 'logrocket'
// MainStem - API
import { Api, MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse } from 'api-new'
// Global - Config
import { ecommerceURL } from 'config'

declare global {
  interface Window {
    _hsq: any
  }
}

export async function apiAuthCurrentUser(): Promise<MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse> {
  let apiResponse = {} as MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse
  const mainStemAPI = new Api()

  await mainStemAPI.api
    .authCurrentUser()
    .then((res) => {
      if (res.status === 200) {
        apiResponse = res.data
        if (apiResponse.wasSuccessful) {
          // If user is not allowed to use app, redirect them to our e-commerce site
          if (!apiResponse.allowPurchasingApp) {
            window.location.href = ecommerceURL + '/impersonate/' + window.localStorage.getItem('mainstem_user_token')
          }

          try {
            // LogRocket Identify
            LogRocket.identify(res.data?.uuid ?? '', {
              name: res.data.friendlyName as any,
              email: res.data.username as any,
              role: res.data.permissionGroup?.name as any,
              organization: res.data.organization?.name as any,
              programType: res.data.programType as any,
              accountSize: res.data.accountSize as any,
              platform: 'Purchase Pro'
            })
            console.log('3rd Party - LogRocket : Initialized')
          } catch {
            console.error('3rd Party - LogRocket : Failed to initialize')
          }
          try {
            if (window._hsq) {
              window._hsq.push(['identify', { id: res.data.uuid, email: res.data.username }])
              console.log('3rd Party - HubSpot : Initialized')
            } else {
              console.error('3rd Party - HubSpot : Failed to initialize')
            }
          } catch {
            console.error('3rd Party - HubSpot : Failed to initialize')
          }
        }
      }
      return res
    })
    .catch((error) => {
      console.error('API Error')
      return error
    })

  return apiResponse
}
