import styled from 'styled-components'

export const SCFeedWrapper = styled.div`
  background-color: #f7fafe;
  border: 1px solid rgb(233, 236, 239);
  height: calc(100vh);
  min-width: 300px;
  padding: 16px;
  position: relative;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 130px;
`

export const SCFeedTitle = styled.div`
  background-image: linear-gradient(238deg, rgb(26, 36, 37) 23%, rgb(6, 35, 67));
  font-size: 20px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  left: 0;
  margin-bottom: 20px;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
`

export const SCFeedCardImageWrapper = styled.div`
  margin-bottom: 20px;
`

export const SCFeedCardImage = styled.img`
  max-width: 100%;
`

export const SCFeedCardWrapper = styled.div`
  margin-top: 40px;
`

export const SCFeedCard = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(222, 226, 230);
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
`

export const SCFeedPinWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -5px;
`

export const SCFeedPinImage = styled.img`
  width: 30px;
`

export const SCFeedCardTitle = styled.div`
  color: #222222;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const SCFeedCardDescription = styled.div`
  color: rgb(134, 142, 150);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
`

export const SCFeedCardButton = styled.a``
